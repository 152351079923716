<template>
	<div class="home">
		<div class="main">
			<div class="left">
				<el-menu @select="select" router :default-active="$route.path" class="el-menu-vertical-demo">
					<el-menu-item index="/matches">
						<span class="icons">
							<img class="icon" v-show="actives != '/matches'" src="../assets/image/home_select.png">
							<img class="icon" v-show="actives == '/matches'" src="../assets/image/home_selected.png">
						</span>
						<span slot="title">Metches</span>
					</el-menu-item>
				</el-menu>
			</div>
			<div class="right">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				actives: '/matches'
			}
		},
		methods: {
			select(e) {
				this.actives = e
			}
		}
	}
</script>

<style scoped lang="less">
	.home {
		width: 100vw;
		height: calc(100vh - 81px);
		background-color: #1c1c1e;
		overflow: hidden;

		.main {
			display: flex;
			height: 100%;

			.left {
				width: 248px;
				height: 100%;

				.el-menu {
					height: 100%;
					background-color: #3a3a3c;
					border-color: #8e8e93;
					.el-menu-item{
						margin: 20px 0;
						color: #8e8e93;
						&:focus,&:hover{
							background-color: #3a3a3c;
						}
						&.is-active{
							color: #fff;
						}
					}
				}

				.icons {
					width: 40px;
					height: 40px;
					display: inline-block;
					font-size: 0;
					margin-right: 10px;

					.icon {
						width: 40px;
						height: 40px;
						display: block;
					}
				}
			}

			.right {
				height: 100%;
				width: calc(100vw - 248px);
				overflow-y: auto;
				/deep/.el-table th, /deep/.el-table tr{
					background: #2c2c2e!important;
				}
				/deep/.el-table td, /deep/.el-table th.is-leaf{
					border-bottom:1px solid #636366!important;
				}
				/deep/.el-input input,/deep/.el-select,/deep/.el-textarea textarea,/deep/.el-range-editor,/deep/.el-range-editor input{
					background: #2c2c2e!important;
					border: none;
					outline: none;
				}
				/deep/.el-pager li,/deep/.el-pagination button:disabled,/deep/.el-pagination button{
					background-color: #1c1c1e;
					color: #787878;
				}
				/deep/.el-pager li.active{
					color: #fff;
				}
			}
		}
	}
</style>
